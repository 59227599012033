import { Component, Input } from '@angular/core';
import { WorkItem } from '../app.component';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss']
})
export class ListCardComponent {

  @Input()
  workItem: WorkItem;
  constructor() {}

}
