<div class="list-card">
  <div class="type" [style.background]="workItem.itemType | color">{{ workItem.itemType }}</div>
  <div class="team">
    <div class="team-text">{{ workItem.team }}</div>
  </div>

  <div class="info scrollbar" id="style-4">
    <div class="title">{{ workItem.title }} - ({{ workItem.id }})</div>
    <div class="note">
      <div *ngFor="let note of workItem.notes">{{ note }}</div>
    </div>
  </div>
</div>
