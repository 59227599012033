import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginService {
  private signedInSubj = new BehaviorSubject<boolean>(false);

  constructor(private afAuth: AngularFireAuth) {}

  async signIn() {
    try {
      const res = await this.afAuth.signInWithEmailAndPassword(
        'read_history@kfleet.com',
        'jegvillese1515'
      );
      this.signedInSubj.next(true);
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  }

  public isLoggedIn(): Observable<boolean> {
    return this.signedInSubj.asObservable();
  }
}
