<div class="top-row">
  <button class="card" (click)="toggleTeam('common')" [ngClass]="{'selected': isSelected('common')}">
    Common
  </button>
  <button class="card" (click)="toggleTeam('purchase')" [ngClass]="{'selected': isSelected('purchase')}">
    Purchase
  </button>
  <button class="card" (click)="toggleTeam('budget')" [ngClass]="{'selected': isSelected('budget')}">
    Budget
  </button>
  <button class="card" (click)="toggleTeam('maintenance')" [ngClass]="{'selected': isSelected('maintenance')}">
    Maintenance
  </button>
  <button class="card" (click)="toggleTeam('logbook')" [ngClass]="{'selected': isSelected('logbook')}">
    Logbook
  </button>
  <button class="card" (click)="toggleTeam('experience')" [ngClass]="{'selected': isSelected('experience')}">
    Experience
  </button>
  <button class="card" (click)="toggleTeam('voyage')" [ngClass]="{'selected': isSelected('voyage')}">
    Voyage
  </button>
  <button class="card" (click)="toggleTeam('docking')" [ngClass]="{'selected': isSelected('docking')}">
    Docking
  </button>
</div>