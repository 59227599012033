import { Component, OnInit, Input } from '@angular/core';
import { WorkItem } from '../app.component';

@Component({
  selector: 'app-list-card-small',
  templateUrl: './list-card-small.component.html',
  styleUrls: ['./list-card-small.component.scss']
})
export class ListCardSmallComponent implements OnInit {

  @Input()
  workItem: WorkItem;

  constructor() { }

  ngOnInit() {
  }

}
