<div class="container">
  <section
    class="filter-desktop"
    style="width: 100%;"
    *ngIf="desktop || filterOpen"
  >
    <app-team-selector
      [teamsSelected]="teamsSelected"
      (filter)="filter()"
    ></app-team-selector>
  </section>

  <button mat-stroked-button (click)="toggleFilters()" *ngIf="!desktop">
    {{ filterOpen ? 'CLOSE' : 'OPEN' }} FILTERS
  </button>

  <div class="search-container">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input matInput placeholder="Search" #search />
    </mat-form-field>
    <mat-form-field style="width: 260px;" appearance="outline">
      <mat-label>From release</mat-label>
      <mat-select
        placeholder="From release"
        [formControl]="fromReleaseControl"
        required
      >
        <ng-container *ngFor="let release of releases$ | async">
          <mat-option
            [value]="release.releaseDate"
            *ngIf="release.releaseDate <= toReleaseControl.value"
          >
            {{ release.version }} - {{ release.releaseDate | date }}</mat-option
          >
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 260px;" appearance="outline">
      <mat-label>To release</mat-label>
      <mat-select
        placeholder="To release"
        [formControl]="toReleaseControl"
        required
      >
        <ng-container *ngFor="let release of releases$ | async">
          <mat-option
            [value]="release.releaseDate"
            *ngIf="release.releaseDate >= fromReleaseControl.value"
          >
            {{ release.version }} - {{ release.releaseDate | date }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="list">
    <div *ngFor="let workItems of filteredGroupedReleases" class="cards">
      <h2 *ngIf="workItems.length > 0" class="release-header">
        {{ workItems[0].releaseVersion }} - {{ workItems[0].releaseType }} -
        {{ workItems[0].releaseDate | date }}
      </h2>
      <app-list-card
        *ngFor="let item of workItems"
        [workItem]="item"
        style="width: 100%; max-width: 1000px;"
      >
      </app-list-card>
      <app-list-card-small
        *ngFor="let item of workItems"
        [workItem]="item"
        style="width: 100%; display: none;"
      >
      </app-list-card-small>
    </div>
  </div>
  <div class="loading" *ngIf="!groupedReleases">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>
