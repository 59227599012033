import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'color'
})
export class ColorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'Features':
        return '#7ed321';
      case 'Bugfix':
        return '#fddc5f';
      default:
        return '#7ed321';
    }
  }
}
