// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAAZhcgkJz4RnETABcNfvt9wlbpwDm4x5s',
    authDomain: 'kfleet-history.firebaseapp.com',
    databaseURL: 'https://kfleet-history.firebaseio.com',
    projectId: 'kfleet-history',
    storageBucket: 'kfleet-history.appspot.com',
    messagingSenderId: '915487713745',
    appId: '1:915487713745:web:c5998a79211b51e1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
