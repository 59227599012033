import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-team-selector',
  templateUrl: './team-selector.component.html',
  styleUrls: ['./team-selector.component.scss']
})
export class TeamSelectorComponent {
  @Input()
  teamsSelected = [];

  @Output()
  filter = new EventEmitter();

  toggleTeam(team) {
    if (this.teamsSelected.indexOf(team) !== -1) {
      const index = this.teamsSelected.indexOf(team);
      this.teamsSelected.splice(index, 1);
    } else {
      this.teamsSelected.push(team.toLowerCase());
    }

    this.filter.emit();
  }

  isSelected(team) {
    return this.teamsSelected.indexOf(team) !== -1;
  }
}
