import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { ReleaseService } from "./release.service";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { fromEvent, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { AutoLoginService } from "./services/auto-login.service";

export interface WorkItem {
  id: number;
  itemType: string;
  note: string;
  releaseId: number;
  team: string;
  title: string;
  releaseDate: Date;
  releaseType: string;
  notes: string[];
  releaseVersion: string;
  releaseNo: string;
}

function itemTypeCompare(a: WorkItem, b: WorkItem) {
  if (a.itemType < b.itemType) {
    return -1;
  }
  if (a.itemType > b.itemType) {
    return 1;
  }

  return 0;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "k-fleet-releases";
  groupedReleases;

  teamsSelected = [];

  filteredGroupedReleases = [];

  releases$;

  @ViewChild("search", { static: true })
  searchInput;

  fromReleaseControl = new FormControl();
  toReleaseControl = new FormControl();

  desktop = true;
  filterOpen = false;

  constructor(
    private releaseService: ReleaseService,
    private activatedRoute: ActivatedRoute,
    private autoLoginService: AutoLoginService
  ) {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (window.innerWidth <= 750) {
      this.desktop = false;
    } else {
      this.desktop = true;
    }
  }

  ngOnInit(): void {
    this.autoLoginService.signIn();
    if (window.innerWidth <= 750) {
      this.desktop = false;
    }
    this.autoLoginService.isLoggedIn().subscribe((val) => {
      if(val) {
        this.getDataAfterLogin();
      }
    });
  }

  private getDataAfterLogin() {
    this.releases$ = this.releaseService.getAllReleases();

    this.releases$.subscribe((res) => {
      this.fromReleaseControl.setValue(res[1].releaseDate); 
      this.toReleaseControl.setValue(res[0].releaseDate); 
    });
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.has("teams")) {
        this.teamsSelected = params.get("teams").split(",");
      }
    });

    this.releaseService
      .getAllWorkItems()
      .pipe(take(1))
      .subscribe((workItems: WorkItem[]) => {
        const groupedReleases = this.groupReleases(workItems);
        const fromRelease = this.fromReleaseControl.value;
        const toRelease = this.toReleaseControl.value;

        this.groupedReleases = groupedReleases.reverse();
        if (this.teamsSelected.length === 0) {
          this.filteredGroupedReleases = this.groupedReleases.map((release) => {
            return release.filter((workItem) => {
              const itemRelease = workItem.releaseDate;
              const inReleaseSpan =
                itemRelease >= fromRelease && itemRelease <= toRelease;
              return inReleaseSpan;
            });
          });
        } else {
          this.filteredGroupedReleases = this.groupedReleases.map((release) => {
            return release.filter((item: WorkItem) => {
              const hasTeam = this.teamsSelected.some(
                (team) => team.indexOf(item.team.toLowerCase()) !== -1
              );
              const itemRelease = item.releaseDate;
              const inReleaseSpan =
                itemRelease >= fromRelease && itemRelease <= toRelease;

              return hasTeam && inReleaseSpan;
            });
          });
        }

        this.fromReleaseControl.valueChanges.subscribe(() => this.filter());
        this.toReleaseControl.valueChanges.subscribe(() => this.filter());
      });
  }

  private groupReleases(workItems: WorkItem[]) {
    const groupedReleases = _.groupBy(workItems, "releaseId");
    const array = [];
    for (const release in groupedReleases) {
      if (groupedReleases.hasOwnProperty(release)) {
        groupedReleases[release] = groupedReleases[release].map((elem) => {
          elem.notes = elem.note.split("\\r\\n");
          return elem;
        });
        array.push(groupedReleases[release].sort(itemTypeCompare).reverse());
      }
    }
    return array;
  }

  filter() {
    const fromRelease = this.fromReleaseControl.value;
    const toRelease = this.toReleaseControl.value;
    if (this.teamsSelected.length === 0) {
      this.filteredGroupedReleases = this.groupedReleases.map((release) => {
        return release.filter((item: WorkItem) => {
          const string = `${item.title} ${item.note} ${item.id} ${item.itemType}`.toLowerCase();
          const searchString = this.searchInput.nativeElement.value;
          const itemRelease = item.releaseDate;
          const inReleaseSpan =
            itemRelease >= fromRelease && itemRelease <= toRelease;
          return (
            string.indexOf(searchString.toLowerCase()) !== -1 && inReleaseSpan
          );
        });
      });
      return;
    }
    this.filteredGroupedReleases = this.groupedReleases.map((release) => {
      return release.filter((item: WorkItem) => {
        const hasTeam = this.teamsSelected.some(
          (team) => team.indexOf(item.team.toLowerCase()) !== -1
        );
        const string = `${item.title} ${item.note} ${item.id} ${item.itemType}`.toLowerCase();
        const searchString = this.searchInput.nativeElement.value;

        const itemRelease = item.releaseDate;
        const inReleaseSpan =
          itemRelease >= fromRelease && itemRelease <= toRelease;

        return (
          string.indexOf(searchString.toLowerCase()) !== -1 &&
          hasTeam &&
          inReleaseSpan
        );
      });
    });
  }

  toggleFilters() {
    this.filterOpen = !this.filterOpen;
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, "keyup").subscribe((event) => {
      this.filter();
    });
  }
}
