import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { WorkItem } from './app.component';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService {
  constructor(private db: AngularFirestore, private afAuth: AngularFireAuth) {}

  getTeam(team: string) {
    return this.db
      .collection<WorkItem>('WorkItems', ref => ref.where('team', '==', team))
      .valueChanges();
  }

  getAllWorkItems() {
    return this.db.collection<WorkItem>('WorkItems').valueChanges();
  }

  getRelease(id: number) {
    return this.db.doc('Releases/' + id).valueChanges();
  }

  getAllReleases() {
    return this.db.collection('Releases', ref => ref.orderBy('releaseDate', 'desc')).valueChanges();
  }
}
