<div class="card">

  <section class="type" [style.background]="workItem.itemType | color">{{workItem.itemType}}</section>
  <section class="team">{{workItem.team}}</section>

  <section class="info">
    <h2> {{workItem.title}} </h2>

    <section class="notes">
      <p *ngFor="let note of workItem.notes">{{ note }}</p>
    </section>

  </section>

</div>